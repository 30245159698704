:root {
  --maroon: #7d0a0a;
  --red: #bf3131;
  --beige: #ead196;
  --yellow: #f3edc8;
  --yellow-background: hsl(52, 64%, 92.5%);
}

html {
  width: 100%;
  background: var(--yellow-background);
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  /*background: var(--yellow);*/
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  body {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  body {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  body {
    width: 65%;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}
