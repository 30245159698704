.date {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: var(--red);
}

.title {
  font-size: 1.7em;
  margin: 0;
  padding: 0;
  color: var(--maroon);
}

.employer {
  font-size: 1em;
  margin: 0;
  padding: 0;
}

.description {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.skills {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 8px;
  flex-wrap: wrap;
}
