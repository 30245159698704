:root {
  --maroon: #7d0a0a;
  --red: #bf3131;
  --beige: #ead196;
  --yellow: #f3edc8;
}

.header_title {
  margin: 0;
  padding: 0;
  color: var(--maroon);
  margin-bottom: 5px;
}

.header_text {
  color: var(--red);
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.container {
  width: 95%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 2rem;
  padding-bottom: 1rem;
  background: var(--beige);
}

.icon {
  width: 2rem;
  filter: brightness(0%);
}

.icons {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.links {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 1rem;
}

.active {
  color: var(--maroon);
  font-weight: bold;
}

button {
  all: unset;
  font-size: 1.3rem;
  color: var(--red);
}

button:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
