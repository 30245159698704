.skill {
  margin: 0;
  padding: 0;
  background: var(--beige);
  display: flex;
  border-radius: 50px;
  flex-direction: row;
  flex-wrap: nowrap;
}

.text {
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  text-align: center;
  color: var(--maroon);
}
