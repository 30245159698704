.icon {
  width: 2rem;
  filter: brightness(0%);
}

.project h2 {
  margin: 0;
  padding: 0;
  color: var(--red);
}

.project h1 {
  margin: 0;
  padding: 0;
  color: var(--maroon);
}

.project p {
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.skills {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 8px;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
